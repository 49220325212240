export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/administracion-web",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Slider principal",
        url: "/slider-home-ecommerce",
        icon: "mdi-view-carousel",
        permissions: ["CHANGE_SLIDER_HOME"],
      },
      {
        name: "Contactos web",
        url: "/concatos-webcommerce",
        icon: "mdi-cellphone-dock",
        permissions: ["CHANGE_CONTACT_WEB"],
      },
      {
        name: "Productos web",
        url: "/productos-webcommerce",
        icon: "mdi-checkbox-multiple-marked",
        permissions: ["CHANGE_PRODUCTS_WEB"],
      },
      {
        name: "Personal",
        url: "/personal-webcommerce",
        icon: "mdi-account-multiple",
        permissions: ["CHANGE_PERSONAL_WEB"],
      },
      {
        name: "Direcciones",
        url: "/direcciones-webcommerce",
        icon: "mdi-directions",
        permissions: ["CHANGE_DIRECTION_WEB"],
      },
    ],
  },
};
