import Vue from "vue";
import Vuex from "vuex";
import master from "./modules/master";
import access from "./modules/access";
import pos from "./modules/pos";
import purchases from "./modules/purchases";
import contabilidad from "./modules/contabilidad";
import bodega from "./modules/bodega";
import administration from "./modules/administration";
import administration_web from "./modules/administration_web";
import crm from "./modules/crm";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    master,
    access,
    pos,
    purchases,
    contabilidad,
    bodega,
    administration,
    administration_web,
    crm,
  },
});
