export default {
    namespaced: true,
    state:{
        menu:[
        	{
                name:'Inicio',
                url: '/bodega',
                icon:'mdi-home',
                permissions: ["HOME"]
            },
            
            {
                name:'Inventario',
                url: '/lista_inventario',
                icon:'mdi-package-variant-closed',
                permissions: ["BODEGA_INVENTARIO"]
            },
            {
                name:'Kardex',
                url: '/kardex_producto',
                icon:'mdi-package-variant-closed',
                permissions: ["BOD_KARDEX"]
            },
            {
                name:'Recepción',
                url: '/recepcion_mercaderia',
                icon:'mdi-call-received',
                permissions: ["BOD_RECEP_COMPRAS","BOD_RECEP_DEV_VENTA","BOD_RECEP_TRANSF"]
            },
            {
                name:'Entrega',
                url: '/entrega_mercaderia',
                icon:'mdi-call-made',
                permissions: ["BOD_ENVIO_VENTA","BOD_ENVIO_DEV_CMP","BOD_ENV_TRANSF"]
            },
            {
                name:'Transferencias',
                url: '/lista_transferencias',
                icon:'mdi-transfer',
                permissions: ["BOD_TRANSFERENCIAS"]
            },
            {
            	
            	config: true,
                name:'Reportes',                
                icon:'mdi-file-document-multiple',
                permissions: ["BOD_ADMIN","BOD_ARQUEO","BOD_PSICO","BOD_KARDEX","LIST_INV_POR_CADUCAR"],
                dropDown:[
                    {
                        name:'Arqueo diario',
                        url: '/arqueo_diario',
                        icon:'mdi-format-list-checks',
                        permissions: ["BOD_ARQUEO"]
                    },
                    {
                        name:'Arqueo general bodega',
                        url: '/arqueo_bodega',
                        icon:'mdi-format-list-checks',
                        permissions: ["BOD_ARQUEO"]
                    },
                    {
                        name:'Recetas psicotrópicos',
                        url: '/lista_psicotropicos',
                        icon:'mdi-format-list-checks',
                        permissions: ["BOD_PSICO"]
                    },
                    {
                        name:'inventario por caducar',
                        url: '/inventario-por-caducar',
                        icon:'mdi-alert-outline',
                        permissions: ["LIST_INV_POR_CADUCAR"]
                    }
                ]
            },
            
            
        ]
    },
    
}