export default {
  namespaced: true,
  state: {
    urlFilesParty: process.env.VUE_APP_API_URL_FILES_SUPPLIERS,
    menu: [
      {
        name: "Inicio",
        url: "/compras",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Proveedores",
        url: "/proveedores",
        icon: "mdi-account-star",
        permissions: ["LISTA_PROVEEDORES"],
      },
      {
        name: "Cotizaciones",
        url: "/cotizaciones-proveedores",
        icon: "mdi-format-list-checks",
        permissions: ["SUPPLIER_QUOTES"],
      },
    ],
  },
  getters: {
    menuCompras(state) {
      var menuAcceso = [];

      for (let i = 0; i < state.menu.length; i++) {
        if (state.menu[i].name == "Inicio") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Proveedores") {
          menuAcceso.push(state.menu[i]);
        }
        if (state.menu[i].name == "Cotizaciones") {
          menuAcceso.push(state.menu[i]);
        }
      }

      return menuAcceso;
    },
  },
};
