import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import EasyCamera from "easy-vue-camera";
import VueHtml2Canvas from "vue-html2canvas";
import "regenerator-runtime/runtime.js";
import routes from "./router/routes";

Vue.use(VueHtml2Canvas);
Vue.use(EasyCamera);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
Vue.use(require("vue-moment"));
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
