export default {
  namespaced: true,
  state: {
    menu: [
      {
        name: "Inicio",
        url: "/inicio-crm",
        icon: "mdi-home",
        permissions: ["HOME"],
      },
      {
        name: "Leads",
        url: "/lista-leads",
        icon: "mdi-account-box-outline",
        permissions: ["CRM_LEADS"],
      },

      {
        name: "Contactos",
        url: "/crm_prospectos",
        icon: "mdi-contacts-outline",
        permissions: ["CRM_CONTACTOS"],
      },

      {
        name: "Oportunidades",
        url: "/lista-oportunidades",
        icon: "mdi-handshake",
        permissions: ["CRM_OPORTUNIDADES"],
      },
      {
        name: "Etapas de venta",
        url: "/pipelines",
        icon: "mdi-filter-cog-outline",
        permissions: ["CRM_PIPELINE"],
      },
      {
        name: "Tareas",
        url: "/lista-tareas",
        icon: "mdi-checkbox-multiple-marked",
        permissions: ["CRM_TAREAS"],
      },
      {
        name: "Ordenes de servicio",
        url: "/lista-ordenes-servicio",
        icon: "mdi-room-service-outline",
        permissions: ["CRM_ORDENES_SERVICIO"],
      },

      // {
      //   name: "Cotizacion",
      //   url: "/crm_cotizacion",
      //   icon: "mdi-file-document-outline",
      //   permissions: ["CRM_COTIZACION"],
      // },

      {
        name: "Horarios de tienda",
        url: "/horario_trabajo",
        icon: "mdi-calendar-clock",
        permissions: ["HORARIOS_TRABAJO_CRM"],
      },
      {
        name: "Horarios de empleados",
        url: "/horario_empleado",
        icon: "mdi-account-switch",
        permissions: ["HORARIOS_EMPLEADO_CRM"],
      },

      {
        name: "Eventos",
        url: "/evento",
        icon: "mdi-calendar-multiple-check",
        permissions: ["EVENTOS_CRM"],
      },
      {
        name: "Reasiganción de eventos",
        url: "/reasignacion-evento",
        icon: "mdi-account-convert",
        permissions: ["CRM_REASIG_MASIVA"],
      },
      {
        name: "Unificar parties",
        url: "/unificar_party",
        icon: "mdi-content-duplicate",
        permissions: ["UNIFICAR_PARTIES_CRM"],
      },

      {
        name: "Configuración",
        url: "/crm-configuracion",
        icon: "mdi-cogs",
        config: true,
        permissions: ["CRM_CONFIG"],
        dropDown: [
          {
            name: "Fuentes de leads",
            url: "/lista-fuentes-leads",
            icon: "mdi-account-box-outline",
            permissions: ["CRM_FUENTES"],
          },

          {
            name: "Campañas de marketing",
            url: "/crm_campania",
            icon: "mdi-bullhorn-outline",
            permissions: ["CRM_CAMP_MARK"],
          },
          {
            name: "Configuración de servicios",
            url: "/configuracion_servicio",
            icon: "mdi-apps",
            permissions: ["CONF_SERVICIO_CRM"],
          },
          {
            name: "Tipo de eventos",
            url: "/tipo_evento",
            icon: "mdi-calendar-question",
            permissions: ["LISTA_TIPO_EVENTO"],
          },

          {
            name: "Estados de eventos",
            url: "/estados_evento",
            icon: "mdi-list-status",
            permissions: ["LISTA_TIPO_EVENTO"],
          },

          {
            name: "Automatizaciones",
            url: "/crm_workflows",
            icon: "mdi-cogs",
            permissions: ["CRM_CONFIG"],
          },

          {
            name: "Plantillas de documentos",
            url: "/template_doc",
            icon: "mdi-file-document-outline",
            permissions: ["CRM_CONFIG"],
          },

          {
            name: "Plantillas de correo",
            url: "/template_email",
            icon: "mdi-email-variant",
            permissions: ["CRM_CONFIG"],
          },

          {
            name: "Plantillas de mensajes",
            url: "/template_message",
            icon: "mdi-message-reply-text-outline",
            permissions: ["CRM_CONFIG"],
          },

          {
            name: "Dispositivos",
            url: "/dispositivos",
            icon: "mdi-cellphone-check",
            permissions: ["WA_DISPOSITIVOS"],
          },

          {
            name: "Formularios",
            url: "/formularios",
            icon: "mdi-content-paste",
            permissions: ["FORMULARIOS_CRM"],
          },
          {
            name: "Contratos",
            url: "/contratos",
            icon: "mdi-file-check",
            permissions: ["CONFIG_CONTRATO_CRM"],
          },
	        {
            name: "Campañas de Whatsapp",
            url: "/campaniaWhatsapp",
            icon: "mdi-whatsapp",
            permissions: ["WA_CAMPANIA"],
          },
          {
            name: "Terminos y plazos",
            url: "/plazos_pago",
            icon: "mdi-cash-multiple",
            permissions: ["CONFIG_TERMS"],
          },
          {
            name: "Usuarios WhatsAppi",
            url: "/usuarios-servicio-wa",
            icon: "mdi-account-star",
            permissions: ["CONFIG_USERS_WAPI"],
          },
          {
            name: "Chatbot",
            url: "/chatbot_admin",
            icon: "mdi-robot-outline",
            permissions: ["CHATBOT"],
          },
        ],
      },
    ],
  },
};
